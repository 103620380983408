<script lang="ts" setup>
import { useAuthStore } from '@/store/auth';
import { useTheme } from 'vuetify';

const authStore = useAuthStore();
const theme = useTheme();

const isLoggedIn = computed(() => {
  return authStore.isLoggedIn;
});

const color = computed(() => {
  return theme.global.name.value === 'dark' ? 'surface' : 'primary';
});

async function auth() {
  if (isLoggedIn) {
    await logOut();
  }

  navigateTo('/');
}
</script>

<template>
  <v-app-bar
    elevation="3"
    :color="color"
  >
    <template #prepend>
      <Logo
        class="logo"
        @click="navigateTo('/')"
      />
    </template>

    <v-spacer />
    <v-btn
      variant="outlined"
      @click="auth"
    >
      {{ isLoggedIn ? 'Uitloggen' : 'Log in' }}
    </v-btn>
  </v-app-bar>
</template>

<style lang="scss" scoped>
.v-btn--icon {
  border-radius: 5px;

  --v-btn-height: 24px;
}

.logo {
  width: 32px;
  height: 32px;
  margin-left: 12px;
  cursor: pointer;
}
</style>
