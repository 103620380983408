<script lang="ts" setup>
import { useTheme } from 'vuetify';
import { useAuthStore } from '@/store/auth';
import File from '@/store/models/File';
import Folder from '@/store/models/Folder';
import { useLoaderStore } from '@/store/loader';

const theme = useTheme();
const route = useRoute();
const { isSmallScreen } = useDisplay();

function leave(event: Event) {
  event.preventDefault();
  logOut();

  return false;
}

onMounted(async () => {
  const { user } = useAuthStore();
  const loaderStore = useLoaderStore();

  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    theme.global.name.value = 'dark';
  } else {
    theme.global.name.value = 'light';
  }

  if (user?.uid != null) {
    loaderStore.start();

    await fetchData(File, `userFiles/${user.uid}/files`);
    await fetchData(Folder, `userFolders/${user.uid}/folders`);

    loaderStore.stop();
  }

  if (isSmallScreen && !route.path.match(/\/pages.*/)) {
    go('/pages');
  }

  window.addEventListener('beforeunload', leave);
});
</script>

<template>
  <v-layout>
    <NavBar />

    <Footer />

    <NuxtPage />
    <!-- <NuxtPwaManifest /> -->
  </v-layout>
</template>
