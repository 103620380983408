<script lang="ts" setup>
import { useSnippetStore } from '@/store/snippet';
import { useEditorStore } from '@/store/editor';
import { useTheme } from 'vuetify';

const route = useRoute();
const snippetStore = useSnippetStore();
const editorStore = useEditorStore();
const theme = useTheme();
const { isSmallScreen, isTouchDevice } = useDisplay();

const themeName = computed(() => {
  return theme.global.name.value;
});

const isSecure = computed(() => {
  return route.path.match(/\/pages.*/) !== null;
});
const isInsecure = computed(() => {
  return route.path.match(/\/templates.*/) !== null;
});
const hasPosition = computed(() => {
  return editorStore.position != null;
});
const saved = computed(() => {
  if (editorStore.isFresh) {
    return 'Text is opgeslagen.';
  }

  return 'Text is nog niet opgeslagen.';
});
</script>

<template>
  <v-app-bar
    class="footer"
    location="bottom"
    :height="isTouchDevice ? 63 : 31"
    flat
  >
    <template
      v-if="isTouchDevice"
      #prepend
    >
      <FooterNav />
    </template>

    <v-spacer />

    <v-fade-transition>
      <v-icon
        v-if="editorStore.copyAction"
        transition="fade-transition"
        icon="$clipboard"
        color="icon"
        :class="`footer-item footer-icon ${themeName}`"
      />
    </v-fade-transition>

    <span
      v-if="hasPosition"
      class="text-icon"
    >
      regel {{ editorStore.lineNumber }}&nbsp;
    </span>

    <span
      v-if="hasPosition"
      class="text-icon footer-item"
    >
      kolom {{ editorStore.column }}
    </span>

    <v-tooltip
      v-if="snippetStore.inSnippetMode"
      text="Stop template modus"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          icon="$snippet"
          :color="snippetStore.inSuggestionMode ? 'info' : 'icon'"
          :class="`footer-item footer-icon ${themeName}`"
          @click="snippetStore.cancel"
        />
      </template>
    </v-tooltip>

    <v-tooltip
      v-if="isSecure && !isSmallScreen"
      text="Dit wordt veilig opgeslagen"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          icon="$secure"
          color="icon"
          :class="`footer-item footer-icon ${themeName}`"
        />
      </template>
    </v-tooltip>

    <v-tooltip
      v-if="isInsecure && !isSmallScreen"
      text="Sla hier geen patiëntgegevens op"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          icon="$insecure"
          color="icon"
          :class="`footer-item footer-icon ${themeName}`"
        />
      </template>
    </v-tooltip>

    <v-tooltip
      v-if="!isSmallScreen"
      :text="saved"
    >
      <template #activator="{ props }">
        <v-icon
          v-bind="props"
          :icon="editorStore.isFresh ? '$fresh' : '$dirty'"
          color="icon"
          :class="`footer-item footer-icon ${themeName}`"
        />
      </template>
    </v-tooltip>
  </v-app-bar>
</template>

<style lang="scss" local>
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-leave-to {
  opacity: 0;
}

.footer {
  border-top: 1px solid var(--v-theme-border);
}

.footer-item {
  margin-right: 1rem;
}

.footer-icon {
  margin-bottom: 2px;
}

.footer-icon.light {
  &:hover svg {
    fill: rgb(0 0 0 / 87%);
  }
}

.footer-icon.dark {
  &:hover svg {
    fill: white;
  }
}
</style>
