<script lang="ts" setup>
import { useSnippetStore } from '@/store/snippet';
import { useEditorStore } from '@/store/editor';

defineEmits(['keypress']);

const { isTouchDevice } = useDisplay();
const snippetStore = useSnippetStore();
const editorStore = useEditorStore();
const inSnippetMode = computed(() => snippetStore.inSnippetMode);
const inSuggestionMode = computed(() => snippetStore.getSuggestionMode);
const isSelectedAll = computed(() => editorStore.isSelectAll);
const hidden = ref(false);

function hide(to: boolean) {
  snippetStore.suggestionMode(!to);
}

function swap(isHidden: boolean) {
  hide(isHidden);
  hidden.value = isHidden;
}

function move(forward: boolean) {
  hidden.value = false;
  if (forward) return snippetStore.next();

  return snippetStore.prev();
}

function selectAll() {
  editorStore.editor?.trigger(null, 'meditor.editor.selectAll', {});
}
function copy() {
  editorStore.editor?.trigger(
    null,
    'editor.action.clipboardCopyWithSyntaxHighlightingAction',
    {}
  );
  editorStore.toggleCopyAction();
}
``;
</script>

<template>
  <v-btn
    v-if="!inSnippetMode"
    icon="$select"
    @click="selectAll"
    @mousedown.prevent=""
  />
  <v-btn
    v-if="isSelectedAll"
    icon="$copy"
    @click="copy"
    @mousedown.prevent=""
  />

  <v-slide-y-transition leave-absolute>
    <div v-if="inSnippetMode && !inSuggestionMode">
      <v-btn
        v-if="hidden"
        icon="$swap"
        @click="swap(false)"
        @mousedown.prevent=""
      />
      <v-btn
        v-if="snippetStore.inSnippetMode && isTouchDevice"
        icon="$back"
        @click.prevent="move(false)"
        @mousedown.prevent=""
      />
      <v-btn
        v-if="snippetStore.inSnippetMode && isTouchDevice"
        icon="$forward"
        @click.prevent="move(true)"
        @mousedown.prevent=""
      />
    </div>
  </v-slide-y-transition>

  <v-slide-y-reverse-transition hide-on-leave>
    <div v-if="inSnippetMode && inSuggestionMode">
      <v-btn
        icon="$swap"
        @click="swap(true)"
        @mousedown.prevent=""
      />
      <v-btn
        id="button-up"
        icon="$up"
        @click="snippetStore.prevSuggestion"
        @mousedown.prevent=""
      />
      <v-btn
        ref="down"
        icon="$down"
        @click="snippetStore.nextSuggestion"
        @mousedown.prevent=""
      />
    </div>
  </v-slide-y-reverse-transition>
</template>
